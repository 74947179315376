import React from "react";
import { isChrome, isFirefox, isIeEdge, isSafari, getBrowser } from "@tightrope/lpscripts/browserdetect";
import Styles from "./css/gwlp.module.scss";
import Data from "./data/gwlp.json";

class gwlp extends React.Component {
  constructor(props) {
    super(props);
    const path = typeof window !== 'undefined' ? window.location.pathname : '';
    let lang = 'default';
    if (path.includes('spa-de-download')) lang = 'de';
    else if (path.includes('spa-fr-download')) lang = 'fr';
    else if (path.includes('spa-pt-download')) lang = 'pt';

    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      isSafari: isSafari(),
      display: "none",
      pageData: props.data,
      data: Data,
      lang: lang,
      isLoading: true
    };
  }

  showBrowsers(browsers) {
    let display = false;
    if (getBrowser() === "ie" && browsers.includes("ie")) {
      display = true;
    }
    else if (getBrowser() === "firefox" && browsers.includes("firefox")) {
      display = true;
    }
   else if (getBrowser() === "chrome" && browsers.includes("chrome")) {
      display = true;
    }
    else if (getBrowser() === "edge" && browsers.includes("edge")) {
      display = true;
    }
   else if (getBrowser() === "safari" && browsers.includes("safari")) {
      display = true;
    }
    else if (getBrowser() === "other" && browsers.includes("other")) {
      display = true;
    }
    if (display) this.setState({ display: "block" });
    else this.setState({ display: "none" });
  }

  componentDidMount() {
    if (this.props.browsers) {
      this.showBrowsers(this.props.browsers);
    } else {
      this.setState({ display: "block" });
    }
    if (this.props.data) {
      this.props.data.then((data) => {
        const instanceData = Object.assign(this.state.data, data);
        this.setState({ data: instanceData });
        this.state.isChrome = isChrome();
        this.state.isFirefox = isFirefox();
        this.state.isIeEdge = isIeEdge();
        this.state.isSafari = isSafari();
        if (isChrome()) {
          const browserData = Object.assign(this.state.data, this.state.data.chrome);
          this.setState({ data: browserData });
        }
        if (isFirefox()) {
          const browserData = Object.assign(this.state.data, this.state.data.firefox);
          this.setState({ data: browserData });
        }
        if (isSafari()) {
          const browserData = Object.assign(this.state.data, this.state.data.safari);
          this.setState({ data: browserData });
        }
        if (isIeEdge()) {
          const browserData = Object.assign(this.state.data, this.state.data.edge);
          this.setState({ data: browserData });
        }
        if (typeof window != "undefined") {
            window.mapParams();
        }
      });
    }

    window.addEventListener("load", () => {
      this.setState({ isLoading: false });
    });

      //OS Detection
      document.querySelectorAll('.animCTA').forEach(p => {

          p.innerText = Data[this.state.lang].button;
  });

  }

  startAnimation() {
    var stepTrack = document.querySelector('.gwlp-module--stepTrack');
    stepTrack.classList.add('gwlp-module--active');

    var listItems = document.querySelectorAll('li');
    if (listItems.length >= 1) {
      listItems[0].classList.add('gwlp-module--stepComplete');
      listItems[0].classList.remove('gwlp-module--isActive');
    }

    if (listItems.length >= 2) {
      listItems[1].classList.add('gwlp-module--isActive');
    }
    if(navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1)
      return;
    setTimeout(function(){
      if(typeof window != "undefined")
        return window.triggerInstall();
    }, 1000);

  }

  render() {
    return (
      <>
        <div id={Styles.gwlp}>
          <div className={`${Styles.preloader} ${this.state.isLoading ? Styles.isLoading : ""}`}>
            <div className={Styles.loader}>
              <div className={Styles.ball}></div>
              <div className={Styles.ball}></div>
              <div className={Styles.ball}></div>
              <div className={Styles.ball}></div>
              <div className={Styles.shadow}></div>
              <div className={Styles.shadow}></div>
              <div className={Styles.shadow}></div>
              <div className={Styles.shadow}></div>
            </div>
          </div>
          <main>
            <section>
              <h1 className = "hc_param">{this.state.data.headline}</h1>
              <h2 className={Styles.subList}>{this.state.data.content}</h2>
              <div className={Styles.compatible}>
                <img alt="checkbox" className={Styles.checkBox} src={this.state.data.checkIcon} />
                <h3>{this.state.data.compatible}</h3>
              </div>
              <img className={`${Styles.cws} cl1`} onClick={this.startAnimation} alt="Chrome Web Store" src={this.state.data.cwsBadge} />
              <button className={`${Styles.animCTA} cl1`} onClick={this.startAnimation}>{Data[this.state.lang].button}</button>
              {this.props.children}

              <div className={Styles.stepContainer}>
                <div className={Styles.stepTrack}>
                  <div className={Styles.blueLine}></div>
                </div>
                <ul>
                  <li className={Styles.isActive}>{Data[this.state.lang].step1}</li>
                  <li>{Data[this.state.lang].step2}</li>
                </ul>
              </div>
            </section>
          </main>
        </div>
      </>
    );
  }
}

export default gwlp;
